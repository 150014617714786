import axios from 'axios';

import firebase from './firebase';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

export const getBookingsCount = async () => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.get('bookings/count', {
    headers: {
      authorization: token,
    },
  });

  return Promise.resolve(response.data);
};

export const getReservationsCount = async () => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.get('bookings/reservations/count', {
    headers: {
      authorization: token,
    },
  });

  return Promise.resolve(response.data);
};

export const getResource = async (name, params) => {
  const token = await firebase.auth().currentUser.getIdToken();

  if (params) {
    Object.keys(params).forEach((key) => {
      if (!Number.isInteger(params[key])) {
        if (!params[key].length) {
          delete params[key];
        }
      }
    });
  }

  const response = await axios.get(name, {
    headers: {
      authorization: token,
    },
    params,
  });

  return Promise.resolve(response.data);
};

export const getBookings = async (params) => {
  const token = await firebase.auth().currentUser.getIdToken();

  Object.keys(params).forEach((key) => {
    if (!Number.isInteger(params[key])) {
      if (!params[key].length && key !== 'withReservations') {
        delete params[key];
      }
    }
  });

  const response = await axios.get('bookings', {
    headers: {
      authorization: token,
    },
    params,
  });

  return Promise.resolve(response.data);
};

export const getReservations = async (params) => {
  const token = await firebase.auth().currentUser.getIdToken();

  Object.keys(params).forEach((key) => {
    if (!Number.isInteger(params[key])) {
      if (!params[key].length) {
        delete params[key];
      }
    }
  });

  const response = await axios.get('bookings/reservations', {
    headers: {
      authorization: token,
    },
    params,
  });

  return Promise.resolve(response.data);
};

export const createBooking = async (values) => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post('bookings', values, {
    headers: {
      authorization: token,
    },
  });

  return Promise.resolve(response.data);
};

export const createReservation = async (values) => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post('bookings/reservations', values, {
    headers: {
      authorization: token,
    },
  });

  return Promise.resolve(response.data);
};

export const updateBooking = async (id, values) => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.put(`bookings/${id}`, values, {
    headers: {
      authorization: token,
    },
  });

  return Promise.resolve(response.data);
};

export const deleteBooking = async (id) => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.delete(`bookings/${id}`, {
    headers: {
      authorization: token,
    },
  });

  return Promise.resolve(response.data);
};

export const deleteReservation = async (id) => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.delete(`bookings/reservations/${id}`, {
    headers: {
      authorization: token,
    },
  });

  return Promise.resolve(response.data);
};

export const getCustomersCount = async (params) => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.get('customers/count', {
    headers: {
      authorization: token,
    },
    params,
  });

  return Promise.resolve(response.data);
};

export const getCustomers = async (params) => {
  const token = await firebase.auth().currentUser.getIdToken();

  if (params) {
    Object.keys(params).forEach((key) => {
      if (!Number.isInteger(params[key])) {
        if (!params[key].length) {
          delete params[key];
        }
      }
    });
  }

  const response = await axios.get('customers', {
    headers: {
      authorization: token,
    },
    params,
  });

  return Promise.resolve(response.data);
};

export const createCustomer = async (customer) => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post('customers', customer, {
    headers: {
      authorization: token,
    },
  });

  return Promise.resolve(response.data);
};

export const deleteCustomer = async (id) => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.delete(`customers/${id}`, {
    headers: {
      authorization: token,
    },
  });

  return Promise.resolve(response.data);
};

export const getWeights = async (params) => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.get('weights', {
    headers: {
      authorization: token,
    },
    params,
  });

  return Promise.resolve(response.data);
};

export const createWeight = async (weight) => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post('weights', weight, {
    headers: {
      authorization: token,
    },
  });

  return Promise.resolve(response.data);
};

export const updateWeight = async (id, weight) => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.put(`weights/${id}`, weight, {
    headers: {
      authorization: token,
    },
  });

  return Promise.resolve(response.data);
};

export const getAvailableWeights = async (params) => {
  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.get('weights/available', {
    headers: {
      authorization: token,
    },
    params,
  });

  return Promise.resolve(response.data);
};
